/* Mobile-first base styles */
.rating-page {
  max-width: 100%;
  padding: 1rem;
  margin: 0 auto;
  background: #fff;
  min-height: 100vh;
}

@media (max-width: 767px) {
  .rating-page {
    padding: 0.75rem;
  }
}

/* Book Header */
.book-header {
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
  gap: 1rem;
}

.book-cover {
  width: 120px;
  height: auto;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.book-info h1 {
  font-size: 1.5rem;
  margin: 0 0 0.5rem 0;
  color: #2d3748;
}

.book-info h2 {
  font-size: 1rem;
  color: #718096;
  margin: 0;
  font-weight: normal;
}

@media (max-width: 767px) {
  .book-header {
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 1.25rem;
  }
  
  .book-cover {
    width: 150px;
  }
  
  .book-info h1 {
    font-size: 1.25rem;
  }
  
  .book-info h2 {
    font-size: 0.9rem;
  }
}

/* Form Styles */
.rating-form {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.form-group {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.form-group label {
  font-weight: 500;
  color: #4a5568;
}

.form-group input[type="text"],
.form-group textarea {
  padding: 0.75rem;
  border: 1px solid #e2e8f0;
  border-radius: 0.375rem;
  font-size: 1rem;
  width: 100%;
  transition: border-color 0.2s;
  color: #2d3748; /* Darker text color for better readability */
}

/* Improve placeholder contrast */
.form-group input::placeholder,
.form-group textarea::placeholder {
  color: #718096; /* Darker placeholder color for better contrast */
  opacity: 1; /* Ensure full opacity */
}

/* Mobile-specific styles */
.rating-form.mobile {
  gap: 1.5rem;
}

.mobile .form-group {
  gap: 0.75rem;
  margin-bottom: 0.5rem;
}

.mobile .form-group label {
  font-size: 1.125rem;
  margin-bottom: 0.25rem;
}

.mobile .form-group input[type="text"],
.mobile .form-group textarea {
  padding: 1rem;
  font-size: 16px; /* Prevents iOS zoom on focus */
  border-radius: 0.5rem;
  border: 1px solid #cbd5e0; /* Slightly darker border for better visibility */
  background-color: #f8fafc; /* Very light background for better contrast */
}

.form-group input[type="text"]:focus,
.form-group textarea:focus {
  outline: none;
  border-color: #4299e1;
  box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.2); /* Slightly stronger focus indicator */
  background-color: #fff; /* White background on focus */
}

.mobile .form-group input[type="text"]:focus,
.mobile .form-group textarea:focus {
  box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.3); /* Even stronger focus indicator for mobile */
}

/* Star Rating Component */
.star-rating {
  display: flex;
  align-items: center;
  gap: 0.25rem;
  user-select: none;
  touch-action: manipulation; /* Changed from 'none' to allow scrolling but prevent zooming */
  position: relative; /* Ensure positioning context for children */
  padding: 0.5rem 0;
}

.star-container {
  cursor: pointer;
  width: 2rem;
  height: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative; /* For better touch targeting */
}

/* Add a larger touch target that's invisible */
.star-container::before {
  content: '';
  position: absolute;
  top: -25%;
  left: -25%;
  right: -25%;
  bottom: -25%;
  z-index: 1;
}

.star {
  width: 100%;
  height: 100%;
  transition: transform 0.1s;
  position: relative;
  z-index: 2; /* Ensure star is above the touch target */
}

.star:hover {
  transform: scale(1.1);
}

.rating-value {
  margin-left: 0.5rem;
  font-weight: 500;
  color: #4a5568;
}

/* Mobile styles */
.star-rating.mobile {
  gap: 0.375rem;
  justify-content: center;
  padding: 0.75rem 0;
  margin: 0 auto;
  max-width: 95%; /* Prevent stars from being too close to edges */
}

.star-rating.mobile .star-container {
  width: 2.75rem;
  height: 2.75rem;
  margin: 0 0.125rem; /* Add slight margin between stars */
}

/* Ensure even spacing between stars on mobile */
.star-rating.mobile .star-container:not(:last-child) {
  margin-right: 0.25rem;
}

/* Landscape mode specific adjustments */
.star-rating.mobile.landscape {
  padding: 0.5rem 0;
  gap: 0.5rem; /* Increase gap in landscape mode */
}

.star-rating.mobile.landscape .star-container {
  width: 2.5rem; /* Slightly smaller in landscape to fit better */
  height: 2.5rem;
}

.star-rating.mobile .rating-value {
  margin-left: 0.75rem;
  font-size: 1.125rem;
  font-weight: 600;
}

/* Ensure tap targets are large enough on mobile */
@media (max-width: 767px) {
  .star-container {
    min-width: 2.5rem;
    min-height: 2.5rem;
  }
  
  /* Add extra padding to improve touch targets */
  .star-rating {
    padding: 0.75rem 0.5rem;
  }
}

/* Tag Selector Component */
.tag-selector {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.tag-selector.mobile {
  gap: 0.75rem;
}

.tag-search {
  position: relative;
}

.tag-search.mobile {
  margin-bottom: 0.5rem;
}

.tag-search-input {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #e2e8f0;
  border-radius: 0.375rem;
  font-size: 1rem;
  color: #2d3748;
}

.mobile .tag-search-input {
  padding: 1rem;
  font-size: 16px; /* Prevents iOS zoom on focus */
  border: 1px solid #cbd5e0; /* Slightly darker border */
  background-color: #f8fafc; /* Very light background */
}

/* Improve placeholder contrast for tag search */
.tag-search-input::placeholder {
  color: #718096; /* Darker placeholder color */
  opacity: 1;
}

.tags-container {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
}

.mobile .tags-container {
  gap: 0.625rem;
}

.tag-button {
  padding: 0.5rem 1rem;
  border: 1px solid #e2e8f0;
  border-radius: 999px;
  background: #fff;
  color: #4a5568;
  cursor: pointer;
  transition: all 0.2s;
  font-size: 0.875rem;
}

.mobile .tag-button {
  padding: 0.625rem 1rem;
  font-size: 0.9375rem; /* Slightly larger for touch */
  min-height: 2.5rem; /* Ensures touch target size */
}

.tag-button:hover {
  background: #f7fafc;
}

.tag-button.selected {
  background: #4299e1;
  color: white;
  border-color: #4299e1;
}

.selected-tags {
  margin-top: 1rem;
}

.mobile .selected-tags {
  margin-top: 0.75rem;
}

.selected-tags h4 {
  margin: 0 0 0.5rem 0;
  color: #4a5568;
}

.selected-tags-container {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
}

.mobile .selected-tags-container {
  gap: 0.625rem;
}

.selected-tag {
  display: inline-flex;
  align-items: center;
  gap: 0.25rem;
  padding: 0.25rem 0.5rem;
  background: #4299e1;
  color: white;
  border-radius: 999px;
  font-size: 0.875rem;
}

.mobile .selected-tag {
  padding: 0.375rem 0.625rem;
  font-size: 0.9375rem;
}

.remove-tag {
  background: none;
  border: none;
  color: white;
  cursor: pointer;
  padding: 0 0.25rem;
  font-size: 1.25rem;
  line-height: 1;
}

.mobile .remove-tag {
  font-size: 1.5rem; /* Larger for touch */
  padding: 0 0.375rem;
}

/* Recommend Checkbox */
.recommend-checkbox {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin: 0.5rem 0;
}

.recommend-checkbox label {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  cursor: pointer;
}

.recommend-checkbox.mobile {
  padding: 0.75rem 0;
  margin: 0.75rem 0;
}

.recommend-checkbox input[type="checkbox"] {
  width: 1.25rem;
  height: 1.25rem;
  cursor: pointer;
}

.recommend-checkbox.mobile input[type="checkbox"] {
  width: 1.75rem;
  height: 1.75rem;
  margin-right: 0.5rem;
  accent-color: #4299e1; /* Customize checkbox color */
}

.recommend-checkbox.mobile label {
  font-size: 1.0625rem;
  display: flex;
  align-items: center;
  gap: 0.75rem;
  padding: 0.5rem 0;
  width: 100%;
}

.recommend-checkbox.mobile span {
  flex: 1;
}

/* Submit Button */
.submit-button {
  background: #4299e1;
  color: white;
  padding: 1rem;
  border: none;
  border-radius: 0.375rem;
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s;
  width: 100%;
  margin-top: 1rem;
}

.submit-button.mobile {
  padding: 1.25rem;
  font-size: 1.125rem;
  border-radius: 0.5rem;
  margin-top: 1.5rem;
  font-weight: 600;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Add subtle shadow for depth */
}

.submit-button:hover {
  background: #3182ce;
}

.submit-button:active {
  background: #2b6cb0;
  transform: translateY(1px);
}

/* Loading State */
.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}

.loading-spinner {
  color: #4299e1;
  font-size: 1.25rem;
}

/* Error State */
.error {
  text-align: center;
  padding: 2rem;
  color: #4a5568;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}

.mobile .error {
  padding: 1.5rem 1rem;
}

.error h2 {
  color: #2d3748;
  margin: 0;
}

.back-button {
  background: #4299e1;
  color: white;
  padding: 0.75rem 1.5rem;
  border: none;
  border-radius: 0.375rem;
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s;
  text-decoration: none;
  margin-top: 1rem;
}

.mobile .back-button {
  padding: 1rem 1.75rem;
  font-size: 1.0625rem;
  border-radius: 0.5rem;
  font-weight: 600;
  width: 100%;
}

.back-button:hover {
  background: #3182ce;
}

.back-button:active {
  background: #2b6cb0;
  transform: translateY(1px);
}

/* Success State */
.success {
  text-align: center;
  padding: 2rem;
  color: #2d3748; /* Darker text for better readability */
}

.success h2 {
  font-size: 1.5rem;
  margin-bottom: 1rem;
  color: #1a202c; /* Even darker heading for better contrast */
}

.mobile .success {
  padding: 1.5rem 1rem;
}

.mobile .success h2 {
  font-size: 1.25rem;
  margin-bottom: 0.75rem;
}

.rating-stats {
  margin-top: 2rem;
  padding: 1.25rem;
  background: #f7fafc;
  border-radius: 0.5rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.rating-stats p {
  margin: 0.5rem 0;
  font-size: 1rem;
  color: #2d3748;
}

.mobile .rating-stats {
  margin-top: 1.5rem;
  padding: 1.25rem 1rem;
  border-radius: 0.75rem;
}

.mobile .rating-stats p {
  font-size: 1.0625rem;
  margin: 0.75rem 0;
  font-weight: 500;
}

.popular-tags {
  margin-top: 1.25rem;
}

.popular-tags h3 {
  margin-bottom: 0.75rem;
  font-size: 1.125rem;
  color: #2d3748;
}

.mobile .popular-tags {
  margin-top: 1.5rem;
}

.mobile .popular-tags h3 {
  font-size: 1.0625rem;
  margin-bottom: 0.625rem;
}

.tag-cloud {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  justify-content: center;
  margin-top: 0.75rem;
}

.mobile .tag-cloud {
  gap: 0.625rem;
}

.tag {
  padding: 0.375rem 0.875rem;
  background: #e2e8f0;
  border-radius: 999px;
  font-size: 0.875rem;
  color: #4a5568;
  font-weight: 500;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
}

.mobile .tag {
  padding: 0.5rem 1rem;
  font-size: 0.9375rem;
  background: #edf2f7; /* Slightly lighter background for better contrast */
  color: #2d3748; /* Darker text for better readability */
}

/* Tablet and Desktop Styles */
@media (min-width: 768px) {
  .rating-page {
    max-width: 768px;
    padding: 2rem;
  }

  .book-cover {
    width: 180px;
  }

  .book-info h1 {
    font-size: 2rem;
  }

  .book-info h2 {
    font-size: 1.25rem;
  }

  .star-container {
    width: 2.5rem;
    height: 2.5rem;
  }

  .form-group {
    gap: 0.75rem;
  }
}
